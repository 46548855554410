import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SongList from './pages/SongList';
import SongDetail from './pages/SongDetail';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SongList />} />
        <Route path="/song/:id" element={<SongDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
