import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Import the icon

const Modal = ({ modalContent, countdown }) => {
  return (
    <dialog id="song_modal" className="modal modal-bottom sm:modal-middle bg-black bg-opacity-30 backdrop-blur-sm">
      <div className="modal-box bg-white backdrop-blur-md bg-opacity-90 text-black text-center">
        <div className="pulse-container m-auto my-4 relative w-64 h-64">
          <div className="absolute inset-0 rounded-2xl bg-red-500 opacity-50 animate-pulse"></div>
          <img src={modalContent.img_url} alt={modalContent.song_name} className="w-64 h-64 object-cover rounded-2xl shadow-xl relative z-10" />
        </div>
        <p className="text-3xl font-bold mt-4">《 {modalContent.song_name} 》</p>
        <p className="text-md font-bold">歌手：{modalContent.singer}</p>
        <p className="text-md font-bold">专辑：{modalContent.album}</p>
        <h3 className="text-sm mt-12">等待 {countdown}s 后将自动关闭弹窗</h3>
        <div className="modal-action">
          <form method="dialog">
            <button className="btn btn-circle btn-outline btn-sm">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </form>
        </div>
      </div>
    </dialog>
  );
};

export default Modal;
