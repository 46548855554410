import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSearch  } from '@fortawesome/free-solid-svg-icons';
import { faBilibili } from '@fortawesome/free-brands-svg-icons';
import { faLanguage, faFont, faUser } from '@fortawesome/free-solid-svg-icons';
import config from '../config';
import Modal from '../components/Modal';

const options = [
  { value: 1, label: '曲名' },
  { value: 2, label: '歌手' },
  { value: 3, label: '专辑' },
  { value: 4, label: '首字母' },
  { value: 5, label: '精确' },
  { value: 6, label: '关键词' },
  { value: 7, label: '模糊' },
];

const sortOptions = [
  { label: '歌名正序', sort: 0, type: 1 },
  { label: '歌名倒序', sort: 1, type: 1 },
  { label: '歌手正序', sort: 0, type: 2 },
  { label: '歌手倒序', sort: 1, type: 2 },
  { label: '专辑正序', sort: 0, type: 3 },
  { label: '专辑倒序', sort: 1, type: 3 },
  { label: '拼音正序', sort: 0, type: 4 },
  { label: '拼音倒序', sort: 1, type: 4 },
];

const CustomControl = ({ children, ...props }) => (
  <components.Control {...props}>
    <span className="ml-2">
      {props.selectProps.icon} {/* 显示传入的图标 */}
    </span>
    {children}
  </components.Control>
);

const SearchBar = ({ onSearch, onSortChange, onReset, languages = [], initials = [], singers = [], onFilter }) => {
  const [type, setType] = useState(1);
  const [keyword, setKeyword] = useState('');

  const handleSearchClick = () => {
    if (keyword.trim() !== '') {
      onSearch({ type, keyword });
    } else {
      onReset(); // 当输入框为空时，重置列表
    }
  };

  const handleTypeChange = (newType) => {
    setType(newType);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      color: 'white',
      borderRadius: '0.5rem',
      backdropFilter: 'blur(10px)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
      fontWeight: 'bold',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',  // 字体设置为白色
      fontWeight: 'bold',  // 设置为加粗
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      paddingTop: 3,
      paddingBottom: 10,
      backgroundColor: 'rgba(0,0,0, 0)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)',
      borderRadius: '0.5rem',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
      color: 'white',  // 设置为白色字体
      fontWeight: state.isSelected ? 'bold' : 'normal',  // 选中的选项为加粗
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'white',  // 无索引内容时提示为白色
      fontWeight: 'bold',  // 设置为加粗
    }),
  };

  return (
    <div className="flex flex-col items-center bg-black shadow-lg bg-opacity-10 rounded-lg backdrop-blur-xl shadow-inner w-full mb-4 p-4">
      <div className="w-full md:flex md:space-x-2">
        <Select
          value={options.find(option => option.value === type)}
          onChange={(selectedOption) => handleTypeChange(selectedOption.value)}
          options={options}
          className="font-bold w-full mb-2 md:mb-0 md:w-1/3"
          classNamePrefix="react-select"
          menuPortalTarget={document.body}
          styles={customSelectStyles}
        />
        <div className="flex items-center w-full md:w-2/3">
          <input
            type="text"
            className="p-2 bg-black bg-opacity-5 border-none rounded-l-lg flex-grow focus:ring-0 backdrop-blur-xl font-bold text-white placeholder-white"
            placeholder="输入关键词"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <button
            className="px-4 py-2 bg-white bg-opacity-10 text-white font-bold rounded-r-lg hover:bg-opacity-20 backdrop-blur-xl shadow-inner transition-all duration-300"
            onClick={handleSearchClick}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-8 gap-2 mt-4 w-full">
        {sortOptions.map((option, index) => (
          <button
            key={index}
            className="px-4 py-2 rounded-lg bg-white bg-opacity-10 text-white hover:bg-opacity-20 font-bold w-full"
            onClick={() => onSortChange(option.sort, option.type)}
          >
            {option.label}
          </button>
        ))}
      </div>
      {/* 使用react-select组件的语言、首字母、歌手筛选 */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-4 w-full">
          <Select
            components={{ Control: CustomControl }}
            options={languages.map(lang => ({ value: lang, label: lang }))}
            onChange={(selectedOption) => onFilter('language', selectedOption?.value)}
            placeholder="语言"
            className="w-full font-bold text-white placeholder-white"
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            icon={<FontAwesomeIcon icon={faLanguage} />}
          />
          <Select
            components={{ Control: CustomControl }}
            options={initials.map(initial => ({ value: initial, label: initial }))}
            onChange={(selectedOption) => onFilter('initial', selectedOption?.value)}
            placeholder="首字母"
            className="w-full font-bold text-white placeholder-white "
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            styles={customSelectStyles}
            icon={<FontAwesomeIcon icon={faFont} />}
          />
          <Select
            components={{ Control: CustomControl }}
            options={singers.map(singer => ({ value: singer, label: singer }))}
            onChange={(selectedOption) => onFilter('singer', selectedOption?.value)}
            placeholder="歌手"
            isSearchable={true}  // 开启搜索功能
            noOptionsMessage={() => '没有找到相关歌手'}  // 自定义无索引内容的提示为中文
            className="w-full font-bold text-white"  // 确保字体为白色
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            icon={<FontAwesomeIcon icon={faUser} />}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                borderRadius: '0.5rem',
                backdropFilter: 'blur(10px)',
                fontWeight: 'bold',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'white',  // 字体设置为白色
                fontWeight: 'bold',  // 设置为加粗
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'white',  // 字体设置为白色
                fontWeight: 'bold',  // 设置为加粗
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
                paddingTop: 3,
                paddingBottom: 10,
                backgroundColor: 'rgba(0,0,0, 0)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)',
                borderRadius: '0.5rem',
                fontWeight: 'bold',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                color: 'white',  // 确保所有选项字体为白色
                fontWeight: state.isSelected ? 'bold' : 'normal',  // 选中项加粗
              }),
              input: (provided) => ({
                ...provided,
                color: 'white',  // 输入框内的搜索内容字体为白色
                fontWeight: 'bold',  // 搜索输入框内的字体加粗
              }),
              noOptionsMessage: (provided) => ({
                ...provided,
                color: 'white',  // 无索引内容时提示为白色
                fontWeight: 'bold',  // 设置为加粗
              }),
            }}
          />
        </div>
    </div>
  );
};

const SongDetail = () => {
  const { id } = useParams();  
  const [data, setData] = useState(null);
  const [songs, setSongs] = useState([]);
  const [filteredSongs, setFilteredSongs] = useState([]); // 用于存储筛选后的歌曲
  const [languages, setLanguages] = useState([]); // 存储语言选项
  const [initials, setInitials] = useState([]); // 存储首字母选项
  const [singers, setSingers] = useState([]); // 存储歌手名选项
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [toggleIndex, setToggleIndex] = useState({}); 
  const [sort, setSort] = useState(0); 
  const [type, setType] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [countdown, setCountdown] = useState(null);
  const [isModalClosed, setIsModalClosed] = useState(false);
  const countdownRef = useRef(null); 
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes('safari') && !userAgent.includes('chrome')) {
      setIsSafari(true);
    }
  }, []);

  useEffect(() => {
    axios.post('https://api.kyom.cn/songslist', {
      uid: config.uid,
      sid: id,
      sort: '0',
      type: '1' 
    })
    .then(response => {
      const songsData = response.data.songs;
      setData(response.data);
      setSongs(songsData);
      setFilteredSongs(songsData); // 初始时显示所有歌曲

      // 动态生成语言、首字母、歌手名筛选项
      const languageOptions = [...new Set(songsData.map(song => song.language))];
      const initialOptions = [...new Set(songsData.map(song => song.pinyin_initial))];
      const singerOptions = [...new Set(songsData.map(song => song.singer))];

      setLanguages(languageOptions);
      setInitials(initialOptions);
      setSingers(singerOptions);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });

    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [id]);

  const fetchSongs = ({ type, keyword }) => {
    axios.post('https://api.kyom.cn/songs/search', {
      uid: config.uid,
      songlist_id: id,
      type,
      keyword
    })
    .then(response => {
      const songsData = response.data.songs;
      setSongs(songsData); 
      setFilteredSongs(songsData); // 更新筛选后的歌曲列表

      // 更新筛选项
      const languageOptions = [...new Set(songsData.map(song => song.language))];
      const initialOptions = [...new Set(songsData.map(song => song.pinyin_initial))];
      const singerOptions = [...new Set(songsData.map(song => song.singer))];

      setLanguages(languageOptions);
      setInitials(initialOptions);
      setSingers(singerOptions);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  };

  const resetSongs = () => {
    setFilteredSongs(songs); // 重置为全部歌曲
  };

  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  useEffect(() => {
    if (countdown !== null && countdown > 0 && showModal && !isModalClosed) {
      // 倒计时运行中且模态框未关闭
      countdownRef.current = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0 && showModal && !isModalClosed) {
      // 当倒计时为0时，跳转逻辑
      // 注释掉跳转逻辑
      /*
      if (isIOS()) {
        window.open(`bilibili://live/${data.roomid}`, '_blank');
      } else if (navigator.userAgent.toLowerCase().includes('android')) {
        window.open(`intent://live/${data.roomid}#Intent;scheme=bilibili;package=tv.danmaku.bili;end`, '_blank');
      } else if (!isMobile) {
        window.open(`https://live.bilibili.com/${data.roomid}`, '_blank');
      }
      */
      handleCloseModal();
    }

    return () => {
      // 清除倒计时
      if (countdownRef.current) {
        clearTimeout(countdownRef.current);
      }
    };
  }, [countdown, showModal, isModalClosed]);

  const handleCloseModal = () => {
    document.getElementById('song_modal').close();
    setIsModalClosed(true); // 标记模态框为已关闭
    setCountdown(null); // 清除倒计时
    setShowModal(false); // 隐藏模态框
    if (countdownRef.current) {
      clearTimeout(countdownRef.current); // 确保倒计时被清除
    }
  };

  const handleCopySong = (song) => {
    const songInfo = `给爷唱 ${song.song_name} - ${song.singer}`;
    navigator.clipboard.writeText(songInfo)
      .then(() => {
        setModalContent(song);
        setCountdown(5); // 设置倒计时为5秒
        setIsModalClosed(false); // 重置标志位，允许倒计时进行
        setShowModal(true); // 显示模态框
        document.getElementById('song_modal').showModal();
      })
      .catch(err => console.error('复制失败:', err));
  };

  const toggleContent = (index) => {
    setToggleIndex(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleSortChange = (newSort, newType) => {
    setSort(newSort);
    setType(newType);

    axios.post('https://api.kyom.cn/songslist', {
      uid: config.uid,
      sid: id,
      sort: newSort,
      type: newType
    })
    .then(response => {
      const songsData = response.data.songs;
      setSongs(songsData);
      setFilteredSongs(songsData);

      // 更新筛选项
      const languageOptions = [...new Set(songsData.map(song => song.language))];
      const initialOptions = [...new Set(songsData.map(song => song.pinyin_initial))];
      const singerOptions = [...new Set(songsData.map(song => song.singer))];

      setLanguages(languageOptions);
      setInitials(initialOptions);
      setSingers(singerOptions);
    })
    .catch(error => {
      console.error('Error updating sort:', error);
    });
  };

  // 筛选逻辑
  const handleFilter = (filterType, value) => {
    if (!value) {
      // 如果没有选择筛选条件，显示全部
      setFilteredSongs(songs);
      return;
    }
    let filtered = songs.filter(song => {
      if (filterType === 'language') {
        return song.language === value;
      } else if (filterType === 'initial') {
        return song.pinyin_initial === value;
      } else if (filterType === 'singer') {
        return song.singer === value;
      }
      return true;
    });
    setFilteredSongs(filtered);
  };

  if (!data) {
    return (
      <div>
        <Header theme="dark" />
        <div className="flex flex-col items-center justify-center h-screen mt-12">
          {/* 加载动画 */}
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-black/70"></div>
          {/* 加载中的文字提示 */}
          <div className="text-center text-black/70 font-bold text-xl mt-4">
            加载中...
          </div>
        </div>
      </div>
    );
  }
  

  return (
    <div 
      className="min-h-screen bg-cover bg-center font-sans md:bg-fixed md:backdrop-none backdrop-blur-lg" 
      style={{ backgroundImage: data.songs_info?.background_img?.url ? `url(${data.songs_info.background_img.url})` : 'none', backgroundAttachment: 'fixed' }}
    >
      <Header theme="dark" />
      <div className="flex flex-col items-center p-6 pt-8 backdrop-blur-lg md:backdrop-blur-none w-full max-w-6xl mx-auto overflow-y-scroll">
        <div className="w-full max-w-4xl">  
        </div>
        {isMobile ? (
          <div>
            <div className="relative p-6 bg-black bg-opacity-10 backdrop-blur-sm rounded-lg shadow-lg mb-4 w-full max-w-4xl">  
              <div 
                className={`absolute top-0 left-0 right-0 h-32 md:h-48 bg-cover bg-center rounded-t-lg ${isSafari ? 'bg-scroll' : 'bg-fixed'}`}
                style={{ 
                  backgroundImage: data.songs_info?.background_img?.url ? `url(${data.songs_info.background_img.url})` : 'none',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              ></div>
              <div className="relative flex flex-col md:flex-row items-center text-white text-center md:text-left space-y-4 md:space-y-0 md:space-x-6 mt-16">
                <img 
                  src={data.face} 
                  alt={data.uname} 
                  className="w-24 h-24 md:w-28 md:h-28 rounded-full border-4 border-white shadow-xl mx-auto md:mx-0 relative z-10"
                  style={{ marginTop: '-12px' }}  
                />
                <div>
                  <h1 className="text-xl md:text-2xl font-bold text-grey-400 mt-2">{data.songs_info?.name}</h1>
                  <h4 className="text-sm md:text-lg font-bold text-grey-400 mb-2">{data.songs_info?.introduction}</h4>
                  <a 
                    href={`https://live.bilibili.com/${data.roomid}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-block px-4 py-2 mt-2 md:px-6 md:py-2 text-lg md:text-xl font-bold text-white bg-blue-400 rounded-lg transition mr-2"
                  >
                    <FontAwesomeIcon icon={faBilibili} style={{ marginBottom: '-2px' }} /> 直播间
                  </a>
                  <a 
                    href={`https://space.bilibili.com/${data.uid}`} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-block px-4 py-2 mt-2 md:mt-4 text-lg md:text-xl font-bold text-white bg-pink-400 rounded-lg transition"
                  >
                    <FontAwesomeIcon icon={faBilibili} style={{ marginBottom: '-2px' }} /> 前往主页
                  </a>
                </div>
              </div>
            </div>
            <SearchBar 
              onSearch={fetchSongs} 
              onSortChange={handleSortChange} 
              onReset={resetSongs}
              languages={languages}
              initials={initials}
              singers={singers}
              onFilter={handleFilter}
            />
            <div className="grid grid-cols-1 gap-4 mt-2 w-full max-w-4xl">  
              {filteredSongs.map((song, index) => (
                <div 
                  key={index} 
                  className="relative rounded-lg shadow-lg overflow-hidden flex bg-white bg-opacity-40 backdrop-blur-lg"
                  onClick={() => handleCopySong(song)}
                >
                  <span className="absolute top right-0 inline-block bg-white bg-opacity-30 backdrop-blur-sm text-sm text-black font-semibold rounded-bl-lg px-2 py-1">
                    {song.language}
                  </span>
                  <div className="relative z-10 flex flex-grow p-4 rounded-l-lg mb-6">
                    <div className="flex-grow">
                      <div className="text-lg font-bold text-black mt-4 min-w-[400px] md:min-w-[400px]">
                        {song.song_name}
                      </div>
                      <div className="text-sm font-bold text-black mb-2">
                        {song.album}
                      </div>
                    </div>
                  </div>
                  <span className="absolute bottom-0 left-0 inline-block bg-white bg-opacity-30 backdrop-blur-sm text-sm text-black font-semibold rounded-tr-lg px-2 py-1">
                    {song.singer}
                  </span>
                  <span className="absolute bottom-0 right-0 inline-block bg-white bg-opacity-10 backdrop-blur-lg text-sm text-white font-semibold rounded-tl-lg px-2 py-1">
                    {song.pinyin_initial}
                  </span>
                  {song.paid === 1 && (song.gift || (song.superchat_num && song.superchat_num > 0)) && (
                  <div className="relative z-10 flex items-center p-4 rounded-r-lg w-1/2">
                    <div className="flex items-center justify-between w-full">
                      <button 
                        onClick={() => toggleContent(index)} 
                        className="text-black hover:text-gray-600"
                      >
                        <FontAwesomeIcon icon={faChevronLeft} /> 
                      </button>
                      {toggleIndex[index] && song.superchat_num && song.superchat_num > 0 ? (
                        <div className="text-center">
                          <div className="text-xl font-bold text-black">{song.superchat_num}</div>
                          <div className="text-sm text-black">SuperChat</div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          {song.gift && (
                            <div className="flex flex-col items-center justify-center text-center">
                              <img 
                                src={song.gift?.webp || '默认图片URL'} 
                                alt={song.gift?.gift_name || '未知礼物'} 
                                className="w-12 h-12 mb-1" 
                              /> 
                              <div className="text-md text-black font-bold">{song.gift?.gift_name || '未知礼物'}</div>
                              <div className="text-sm text-black font-bold">{song.gift?.gift_value}{song.gift?.type}</div>
                            </div>
                          )}
                        </div>
                      )}
                      <button 
                        onClick={() => toggleContent(index)} 
                        className="text-black hover:text-gray-600"
                      >
                        <FontAwesomeIcon icon={faChevronRight} /> 
                      </button>
                    </div>
                  </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className="flex items-center space-x-6 mb-4 p-6 bg-black bg-opacity-10 backdrop-blur-lg rounded-lg shadow-2xl w-full">  
              <img 
                src={data.face} 
                alt={data.uname} 
                className="w-28 h-28 rounded-full border-4 border-white shadow-xl"
              />
              <div>
                <h1 className="text-4xl font-bold text-white">{data.uname}</h1>
                <a 
                  href={`https://live.bilibili.com/${data.roomid}`} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-block pl-4 pr-4 py-2 mt-2 text-xl font-bold text-white bg-opacity-50 bg-pink-500 rounded-lg shadow-lg backdrop-blur-lg hover:bg-opacity-80 transition mr-2"
                >
                  <FontAwesomeIcon icon={faBilibili} style={{ marginBottom: '-2px' }} /> 前往直播间
                </a>
                <a 
                  href={`https://space.bilibili.com/${data.uid}`} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-block px-6 py-2 mt-4 text-xl font-bold text-white bg-opacity-50 bg-blue-500 rounded-lg shadow-lg backdrop-blur-lg hover:bg-opacity-80 transition"
                >
                  <FontAwesomeIcon icon={faBilibili} style={{ marginBottom: '-2px' }} /> 前往主页
                </a>
              </div>
            </div>
            <SearchBar 
              onSearch={fetchSongs} 
              onSortChange={handleSortChange} 
              onReset={resetSongs}
              languages={languages}
              initials={initials}
              singers={singers}
              onFilter={handleFilter}
            />
            <div className="mt-6 w-full">
              <table className="table w-full table-auto rounded-lg shadow-lg backdrop-blur-2xl bg-black bg-opacity-15">
                <thead>
                  <tr>
                    <th className="text-center text-lg text-white font-bold min-w-32">封面</th> 
                    <th className="text-lg text-white font-bold w-64">曲名</th>
                    <th className="text-center text-lg text-white font-bold w-4">字母</th>
                    <th className="text-center text-lg text-white font-bold w-32">专辑</th>
                    <th className="text-center text-lg text-white font-bold w-64">语言</th>
                    <th className="text-center text-lg text-white font-bold w-64">礼物</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSongs.map((song, index) => (
                    <tr key={index} className="border-transparent"  onClick={() => handleCopySong(song)}>
                      <td className="flex justify-center items-center">
                        <img 
                          src={song.img_url} 
                          alt={song.song_name} 
                          className="w-20 h-20 object-cover rounded-lg shadow-md m-auto"  
                        />
                      </td>
                      <td className="text-left">
                        <div className="text-xl text-white font-bold min-w-[200px] md:min-w-[200px]">{song.song_name}</div>
                        <div className="text-sm text-white font-semibold text-opacity-50">{song.singer}</div>
                      </td>
                      <td className="text-center text-white font-semibold">{song.pinyin_initial}</td>
                      <td className="text-center text-white font-semibold">{song.album}</td>
                      <td className="text-center text-white font-semibold">{song.language}</td>
                      <td className="text-center text-white font-semibold">
                        {song.paid === 1 && (song.gift || (song.superchat_num && song.superchat_num > 0)) && (
                          <div className="relative z-10 flex items-center justify-center p-4 rounded-r-lg w-full">
                            <div className="flex items-center">
                              <button 
                                onClick={() => toggleContent(index)} 
                                className="text-white hover:text-gray-400"
                              >
                                <FontAwesomeIcon icon={faChevronLeft} /> 
                              </button>
                              {(toggleIndex[index] && song.superchat_num && song.superchat_num > 0) || !song.gift ? (
                                <div className="text-center mx-4">
                                  <div className="text-4xl font-bold text-white">{song.superchat_num}</div>
                                  <div className="text-xl text-white">SuperChat</div>
                                </div>
                              ) : (
                                <div className="flex flex-col items-center justify-center text-center mx-4">
                                  {song.gift && (
                                    <>
                                      <img 
                                        src={song.gift?.webp || '默认图片URL'} 
                                        alt={song.gift?.gift_name || '未知礼物'} 
                                        className="w-12 h-12 mb-1" 
                                      />
                                      <div className="text-md text-white font-bold">{song.gift?.gift_name || '未知礼物'}</div>
                                      <div className="text-sm text-white font-bold">{song.gift?.gift_value}{song.gift?.type}</div>
                                    </>
                                  )}
                                </div>
                              )}
                              <button 
                                onClick={() => toggleContent(index)} 
                                className="text-white hover:text-gray-400"
                              >
                                <FontAwesomeIcon icon={faChevronRight} /> 
                              </button>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <Modal 
          showModal={showModal} 
          setShowModal={setShowModal} 
          modalContent={modalContent} 
          countdown={countdown} 
          onClose={handleCloseModal}
        />
      </div>
    </div>
  );
}

export default SongDetail;
